<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <div class="attendance" v-else>
      <h5 class="font-weight-bolder">Attendance SMS alert settings</h5>
      <b-form-group
        label="Enable the 'Send Absenteeism SMS Alert' option for all sessions when marking attendance for the first time:">
        <span v-if="loadingAttendanceAlertSettings" class="text-warning">Loading settings...</span>
        <div v-if="!loadingAttendanceAlertSettings">
          <b-form-checkbox @change="updateAttendanceAlertSettings"
            v-model="attendance_alert.absentees_on_marking_to_student" name="flavour-4a" inline>Enable for
            Students</b-form-checkbox>
          <b-form-checkbox @change="updateAttendanceAlertSettings"
            v-model="attendance_alert.absentees_on_marking_to_parent" name="flavour-4a" inline>Enable for
            Parents</b-form-checkbox>
        </div>
        <span class="text-danger">When this option is enabled, SMS alerts cannot be disabled by faculties and are sent
          automatically when attendance is marked.</span>
      </b-form-group>
      <br />
      <h5 class="font-weight-bolder">Configure/add attendance options</h5>
      <div ref="attendenceForm"></div>
      <b-form class="mt-4" v-if="addAttendence" @submit.prevent="addAttendenceType()">
        <b-form-group>
          <span class="alert alert-success text-center" v-if="responseErrors.attendenceCreated">{{
            responseErrors.attendenceCreated[0]
            }}</span>
        </b-form-group>

        <b-form-group>
          <b-input placeholder="Attendance Name" v-model="attendence.name"
            :class="{ 'is-invalid': responseErrors.name }" class="text-center"></b-input>
          <span class="text-danger" v-if="responseErrors.name">{{
            responseErrors.name[0]
            }}</span>
        </b-form-group>

        <b-form-group>
          <div class="d-flex">
            <b-form-radio :value="1" v-model="attendence.positive_report_value">Present</b-form-radio>
            <b-form-radio v-model="attendence.positive_report_value" :value="0" class="ml-3">Absent</b-form-radio>
          </div>
        </b-form-group>

        <!-- <b-form-group>
          <b-form-select
            v-model="attendence.color"
            :options="attendenceColors"
            :class="{ 'is-invalid': responseErrors.color }"
            class="text-center"
          >
          </b-form-select>
          <span class="text-danger ml-4" v-if="responseErrors.color">{{
            responseErrors.color[0]
          }}</span>
        </b-form-group> -->

        <b-form-group>
          <div class="d-flex justify-content-between flex-wrap">
            <b-button variant="primary" type="submit" size="md" :disabled="attendenceBtn.disabled">{{ attendenceBtn.text
              }}</b-button>
            <b-button variant="danger" type="submit" size="md" :disabled="attendenceBtn.disabled" @click="cancel()">
              Cancel</b-button>
          </div>
        </b-form-group>
      </b-form>
      <br />
      <b-button variant="primary" size="md" class=" mt-2" v-if="addAttendence == false"
        @click="addAttendence = !addAttendence">Add Attendance Type</b-button>
      <table class="table table-striped table-bordered" v-if="!addAttendence">
        <thead class="text-left thead-dark">
          <tr>
            <th>Attendance Type</th>
            <th>Attendance Code</th>
            <th>Present/Absent</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(attendence, attendenceIndex) in attendenceTypes" :key="attendenceIndex">
            <td class="align-middle">{{ attendence.name }}</td>
            <td class="align-middle">{{ attendence.code }}</td>
            <td class="align-middle">{{ attendence.positive_report_value ? 'Present' : 'Absent' }}</td>
            <!-- <td class="align-middle">
              <span :style="{ color: attendence.color }"
                ><i class="fas fa-circle"></i
              ></span>
            </td> -->
            <td class="align-middle">
              <span @click="getSpecificAttendence(attendence.id)" class="btn m-0 p-0"><i class="far fa-edit" v-if="
  attendence.name !== 'Present' &&
  attendence.name !== 'Absent'
              "></i></span>
            </td>
          </tr>
        </tbody>
      </table>
      <br>
      <h5 class="font-weight-bolder">Attendance Lock Settings</h5>
      <h6 class="font-weight-bolder">Attendance Locking </h6>
      <b-form-group>
        <b-form-checkbox v-model="attendance_lock.enable_autolocking_of_attendance" name="flavour-4a" inline>
          Enable Auto-locking of attendance (Attendance locking time will be calculated starting from 6 PM on any
          selected date.)
        </b-form-checkbox>
        <span v-if="loadingAttendanceLockSettings" class="text-warning"> Save Attendance Lock settings...</span>
      </b-form-group>
      <b-form-group v-if="attendance_lock.enable_autolocking_of_attendance">
        <b-row class="mx-2 my-1">
          <span @click="enableLockinghours(true)" class="btn m-0 p-0"><i class="far fa-edit"></i></span>
        </b-row>
        <b-row class="mx-2 my-1">
          <span class="mr-1" style="align-self: center;">Lock Attendance after</span>
          <b-form-input max="170" :min="12" :disabled="attendance_lock.enable_locking_hours" class="mr-1"
            style="width: 60px;" v-model="attendance_lock.locking_hours"></b-form-input>
          <span class="mr-1" style="align-self: center;"> hours.</span>
          <span class="mr-1" style="color:red;padding-top: 7px;" v-if="attendance_lock.locking_hours < 12">Min value
            12</span>
          <span class="mr-1" style="color:red;padding-top: 7px;" v-if="attendance_lock.locking_hours > 170">Max value
            170</span>
        </b-row>
      </b-form-group>
      <div v-if="attendance_lock.enable_autolocking_of_attendance">
        <h6 class="font-weight-bolder">Attendance Unlocking </h6>
        <b-form-group>
          <b-row class="mx-2 my-1">
            <span class="mr-1" style="align-self: center;">Level 1 : Send Unlock Request to HOD if within</span>
            <b-form-input :disabled="attendance_lock.enable_locking_hours" class="mr-1" style="width: 60px;"
              v-model="attendance_lock.hod_unlocking_hours"></b-form-input>
            <span class="mr-1" style="align-self: center;"> of Attendance Locking</span>
          </b-row>
          <b-row class="mx-2 my-1">
            <span class="mr-1" style="align-self: center;">Level 2 : Unlock Request will be send to Admin </span>
            <b-form-select style="width: 200px;" :disabled="attendance_lock.enable_locking_hours"
              :options="adminOptions" v-model="attendance_lock.unlock_by_admin_id"></b-form-select>
          </b-row>
          <b-row class="mx-2 my-1" v-if="!attendance_lock.enable_locking_hours">
            <b-button @click="updateAttendanceLockSettings()"
              :disabled="12 > attendance_lock.locking_hours || 170 < attendance_lock.locking_hours"
              variant="primary">Save</b-button>
          </b-row>
        </b-form-group>
      </div>
      <h5 class="font-weight-bolder">Topic Selection</h5>
      <b-form-group>
        <span v-if="loadingAttendanceTopicSettings" class="text-warning">Loading settings...</span>
        <div>
          <b-form-checkbox @change="updateAttendanceTopicSettings" v-model="attendance_topic_selection_mandatory"
            name="flavour-4a" inline>Make Topic Selection Mandatory</b-form-checkbox>
        </div>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
   created() {
     this.getAttendenceType();
     this.getAttendanceAlertSettings();
     this.getAdmins();
     this.getAttendanceLockSettings();
     this.getAttendanceTopicSettings();
  },
  data() {
    return {
      fetchData: false,
      attendenceId: "",
      attendence: {
        name: "",
        color: null,
        code: "",
        positive_report_value: null,
      },
      attendenceTypes: [],
      attendenceBtn: {
        text: "Submit",
        disabled: false,
      },
      addAttendence: false,
      responseErrors: {},
      attendenceColors: [
        { value: null, text: "Select color", disabled: true },
        { value: "#3bcdac", text: "Color: Shamrock", disabled: false },
        { value: "#f65888", text: "Color: French Rose", disabled: false },
        { value: "#f7b265", text: "Color: Rajah", disabled: false },
        { value: "#e8f617", text: "Color: Lemon", disabled: false },
        { value: "#7b4403", text: "Color: Peru Tan", disabled: false },
        { value: "#03367b", text: "Color: Catalina Blue", disabled: false },
        { value: "#173440", text: "Color: Big Stone", disabled: false },
        { value: "#427302", text: "Color: Verdun Green", disabled: false },
      ],
      attendance_lock:{
        enable_autolocking_of_attendance:false,
        locking_hours:'24',
        enable_locking_hours:true,
        hod_unlocking_hours:'48',
        unlock_by_admin_id:null
      },
      adminOptions:[],
      attendance_alert: {
        absentees_on_marking_to_student: false,
        absentees_on_marking_to_parent: false,
      },
      attendance_topic_selection_mandatory: false,
      loadingAttendanceAlertSettings: false,
      loadingAttendanceLockSettings: false,
      loadingAttendanceTopicSettings: false,
    };
  },
  watch:{
  'attendance_lock.enable_autolocking_of_attendance':function(){
    if (!this.attendance_lock.enable_autolocking_of_attendance){
      this.attendance_lock.locking_hours = null;
    }
    else{
      this.attendance_lock.locking_hours = '24';
    }      
    this.updateAttendanceLockSettings();
  }
    
  },
  methods: {
    enableLockinghours() {
      this.attendance_lock.enable_locking_hours = false;
    },
    async getAdmins() {
      //get institutionfaculties
      const url = this.$store.getters.getAPIKey.instituitionAdmin;
      await axios
        .get(url)
        .then((response) => {
          const faculties = response.data;
          faculties.forEach(({ id, first_name, last_name }) => {
            this.adminOptions.push({
              value: id,
              text: first_name.concat(" ", last_name),
            });
          });
          this.adminOptions.sort((a, b) => a.text.localeCompare(b.text));
          this.$forceUpdate();
          response;
        })
        .catch((error) => {
          error;
        });
    },
    async getAttendanceAlertSettings() {
      this.loadingAttendanceAlertSettings = true;
      const settingKey = "attendance_alert";
      let mainUrl = this.$store.getters.getAPIKey.institutionSettings;
      const url = mainUrl.replace("setting_key", settingKey);
      await axios
        .get(url)
        .then((response) => {
          let setting = response.data;
          if (setting) {
            this.attendance_alert.absentees_on_marking_to_student = setting.absentees_on_marking_to_student;
            this.attendance_alert.absentees_on_marking_to_parent = setting.absentees_on_marking_to_parent;
          }
        })
        .catch(() => {
          this.$toast.error(`Failed to Load attendance settings`, {
            top: "top",
            duration: 6000,
          });
        });
      this.loadingAttendanceAlertSettings = false;
    },
    async getAttendanceLockSettings() {
      this.loadingAttendanceLockSettings = true;
      const settingKey = "attendance_lock";
      let mainUrl = this.$store.getters.getAPIKey.institutionSettings;
      const url = mainUrl.replace("setting_key", settingKey);
      await axios
        .get(url)
        .then((response) => {
          let setting = response.data;
          if (setting) {
            this.attendance_lock.enable_autolocking_of_attendance = setting.enable_autolocking_of_attendance;
            this.attendance_lock.locking_hours = setting.locking_hours;
            this.attendance_lock.hod_unlocking_hours = setting.hod_unlocking_hours;
            this.attendance_lock.unlock_by_admin_id = setting.unlock_by_admin_id;
          }
        })
        .catch(() => {
          this.$toast.error(`Failed to Load attendance settings`, {
            top: "top",
            duration: 6000,
          });
        });
      this.loadingAttendanceLockSettings = false;
    },
    
    async getAttendanceTopicSettings() {
      this.loadingAttendanceTopicSettings = true;
      const settingKey = "attendance_topic_selection_mandatory";
      let mainUrl = this.$store.getters.getAPIKey.institutionSettings;
      const url = mainUrl.replace("setting_key", settingKey);
      await axios
        .get(url)
        .then((response) => {
          let setting = response.data;
          if (setting) {
            this.attendance_topic_selection_mandatory = true;
          }
        })
        .catch(() => {
          this.$toast.error(`Failed to Load attendance settings`, {
            top: "top",
            duration: 6000,
          });
        });
      this.loadingAttendanceTopicSettings = false;
    },
    async updateAttendanceAlertSettings() {
      this.loadingAttendanceAlertSettings = true;
      const settingKey = "attendance_alert";
      let mainUrl = this.$store.getters.getAPIKey.institutionSettings;
      const url = mainUrl.replace("setting_key", settingKey);

      await axios
        .post(url, { attendance_alert: this.attendance_alert })
        .then(() => {
          this.$toast.success(`Attendance settings updated`, {
            top: "top",
            duration: 3000,
          });
        })
        .catch(() => {
          this.$toast.error(`Failed to update attendance settings`, {
            top: "top",
            duration: 3000,
          });
        });
      this.loadingAttendanceAlertSettings = false;
    },
    async updateAttendanceLockSettings() {
      this.loadingAttendanceLockSettings = true;
      const settingKey = "attendance_lock";
      let mainUrl = this.$store.getters.getAPIKey.institutionSettings;
      const url = mainUrl.replace("setting_key", settingKey);
      await axios
        .post(url, { attendance_lock: this.attendance_lock })
        .then(() => {
          
        })
        .catch(() => {
          this.$toast.error(`Failed to update attendance settings`, {
            top: "top",
            duration: 3000,
          });
        });
      this.loadingAttendanceLockSettings = false;
    },
    
    async updateAttendanceTopicSettings() {
      this.loadingAttendanceTopicSettings = true;
      const settingKey = "attendance_topic_selection_mandatory";
      let mainUrl = this.$store.getters.getAPIKey.institutionSettings;
      const url = mainUrl.replace("setting_key", settingKey);

      await axios
        .post(url, { attendance_topic_selection_mandatory: this.attendance_topic_selection_mandatory })
        .then(() => {
          this.$toast.success(`Attendance settings updated`, {
            top: "top",
            duration: 3000,
          });
        })
        .catch(() => {
          this.$toast.error(`Failed to update attendance settings`, {
            top: "top",
            duration: 3000,
          });
        });
      this.loadingAttendanceTopicSettings = false;
    },
    addAttendenceType() {
      this.attendenceBtn.text = "Please wait...";
      this.attendenceBtn.disabled = true;
      if (this.attendenceId != "") {
        this.updateAttendence();
      } else {
        if (this.attendence.name != "") {
          this.attendence.code = this.attendence.name.charAt(0);
          this.attendence.code = this.attendence.code.toUpperCase();
        }
        const url = this.$store.getters.getAPIKey.attendenceType;
        axios
          .post(url, this.attendence)
          .then((response) => {
            this.responseErrors = {
              attendenceCreated: [
                this.attendence.name + " is successfully created.",
              ],
            };
            this.getAttendenceType();
            this.clearResponse();
            response;
          })
          .catch((error) => {
            this.responseErrors = error.response.data.errors;
            this.clearResponse();
            error;
          });
      }
    },
    getAttendenceType() {
      this.fetchData = true;
      const url = this.$store.getters.getAPIKey.attendenceType;
      axios
        .get(url)
        .then((response) => {
          this.attendenceTypes = [];
          this.attendenceTypes = response.data;
          this.fetchData = false;
          response;
        })
        .catch((error) => {
          error;
        });
    },
    getSpecificAttendence(attendence_id) {
      this.$smoothScroll({
        scrollTo: this.$refs.attendenceForm,
        duration: 1000,
        offset: -50,
      });
      const url =
        this.$store.getters.getAPIKey.attendenceType + `/${attendence_id}`;
      axios
        .get(url)
        .then((response) => {
          this.addAttendence = true;
          this.attendenceId = response.data.id;
          this.attendence.name = response.data.name;
          this.attendence.color = response.data.color;
          this.attendence.code = response.data.code;
          this.attendence.positive_report_value = response.data.positive_report_value;
          response;
        })
        .catch((error) => {
          error;
        });
    },
    updateAttendence() {
      this.attendenceBtn.text = "Updating...";
      this.attendenceBtn.disabled = true;
      const url =
        this.$store.getters.getAPIKey.attendenceType + `/${this.attendenceId}`;

      axios
        .put(url, this.attendence)
        .then((response) => {
          this.responseErrors = {
            attendenceCreated: [
              this.attendence.name + " is successfully updated.",
            ],
          };
          this.attendenceId = "";
          this.getAttendenceType();
          this.clearResponse();
          response;
        })
        .catch((error) => {
          this.responseErrors = error.response.data.errors;
          this.clearResponse();
          error;
        });
    },

    cancel() {
      this.addAttendence = false;
      this.attendence.name = "";
      this.attendence.code = "";
      this.attendence.color = null;
      this.attendence.positive_report_value = null;
      this.addAttendence = false;
    },
    clearResponse() {
      setTimeout(() => {
        if (this.responseErrors.attendenceCreated) {
          this.attendence.name = "";
          this.attendence.code = "";
          this.attendence.color = null;
          this.attendence.positive_report_value = null;
          this.addAttendence = false;
        }
        this.responseErrors = {};
        this.attendenceBtn.text = "Submit";
        this.attendenceBtn.disabled = false;
      }, 3000);
    },
  },
};
</script>
