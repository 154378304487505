<template>
  <div>
    <div class="loading" v-if="fetchData">
      <load-data></load-data>
    </div>
    <b-container v-else>
      <table class="table table-bordered table-striped">
        <tbody>
          <tr class="table-header" style="background-color: gray;">
            <th>Faculty</th>
            <th>Date</th>
            <th>Course</th>
            <th>Session</th>
            <th>Send To</th>
            <th>Class</th>
            <th>Programme</th>
            <th>Reason</th>
            <th>Requested On</th>
            <th>Approved/ Rejected By</th>
            <th>Approved/ Rejected On</th>
            <th>Status</th>
          </tr>
          <tr v-for="(log, rIndex) in RequestLog" :key="rIndex">
            <td>{{ faculties[log.created_by].first_name }} {{ faculties[log.created_by].last_name }}</td>
            <td>{{ moment(log.date, 'YYYY-MM-DD').format('DD/MM/YYYY') }}</td>
            <td>{{ courses[log.course_id].name }}({{ courses[log.course_id].code }})</td>
            <td>
              <span v-for="(s, sindex) in log.sessions" :key="sindex">
                {{ s.name }}<span v-if="log.sessions.length > 1">,</span>
              </span>
            </td>
            <td>
              <span v-for="(f, findex) in log.approvers" :key="findex">
                {{ f.first_name }} {{ f.last_name }}<span v-if="log.approvers.length > 1">,</span>
              </span>
            </td>
            <td>{{ subgroups[log.subgroup_id].name }}</td>
            <td>
              {{ groups[log.group_id].code }}</td>
            <td>{{ log.reason }}</td>
            <td>
              {{ log.created_at.format("DD/MM/YYYY") }} {{ log.created_at.format("hh:mm a") }}
            </td>
            <td>
              <p v-if="log.approval_status == 1 || log.approval_status == 2">
                {{ faculties[log.updated_by].first_name }} {{ faculties[log.updated_by].last_name }}
              </p>
            </td>
            <td>
              <p v-if="log.approval_status == 1 || log.approval_status == 2">
                {{ log.updated_at.format("DD/MM/YYYY") }} {{ log.updated_at.format("hh:mm a") }}
              </p>
            </td>
            <td>
              <p v-if="log.approval_status == 0 && faculties[log.created_by].id != faculty">
                <b-button class="ml-2" variant="success" @click="updatestatus(log.id, 1)" size="sm">Approve</b-button>
                <b-button class="ml-2" variant="danger" @click="updatestatus(log.id, 2)" size="sm">Reject</b-button>
              </p>
              <p v-if="log.approval_status == 0 && faculties[log.created_by].id == faculty">
                Pending
              </p>
              <p v-if="log.approval_status == 1">
                Approved
              </p>
              <p v-if="log.approval_status == 2">
                Rejected
              </p>
              <p v-if="log.approval_status == 3">
                Expired
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: [],
  components: {
  },
  async created() {
    this.fetchData = true;
    await this.getRequestLog();
    this.fetchData = false;
  },

  data() {
    return {
      fetchData: false,
      RequestLog: [],
      groups: [],
      subgroups: [],
      faculties: [],
      courses: [],
      faculty: null
    };
  },
  methods: {
    async updatestatus(id, status) {
      const url = this.$store.getters.getAPIKey.mainAPI + `/attendanceunlocks/approve`;
      await this.$axios
        .post(url, {
          id: id,
          status: status
        })
        .then((response) => {
          response;
          this.getRequestLog();
        })
        .catch((error) => {
          error;
        });

    },
    async getRequestLog() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/attendanceunlocks`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.RequestLog = response.data.requestlog;
          this.groups = response.data.groups;
          this.subgroups = response.data.subgroups;
          this.faculties = response.data.faculties;
          this.courses = response.data.courses;
          this.RequestLog.forEach((log) => {
            log.created_at = moment(
              moment.utc(log.created_at, "YYYY-MM-DD HH:mm:ss").toDate()
            );
            log.updated_at = moment(
              moment.utc(log.updated_at, "YYYY-MM-DD HH:mm:ss").toDate()
            )
          })

          this.faculty = response.data.faculty;
        })
        .catch((error) => {
          error;
        });
    },
  },
  watch: {
  },
};
</script>

<style scoped>
.table-header {
  background-color: gray !important;
}
</style>
