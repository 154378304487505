<template>
  <div>

    <b-col sm="12">
      <h3 style="text-align: center; font-size:18px !important;">
        Edit Attendance
      </h3>
      <b-modal id="unlockAttendanceModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-header-close
        hide-footer hide-header centered size="md">
        <div>
          <b-col sm="12">
            <h3 style="text-align: center; font-size:18px !important;">
              Unlock Attendance
            </h3>
            <b-form-group>
              <label class="required-label" for="">Reason (Minimum 10 character):</label>
              <b-form-input id="input-1" v-model="unlock_reason" type="text" placeholder="Enter Reason"></b-form-input>
            </b-form-group>
            <b-button @click="$bvModal.hide('unlockAttendanceModel')" class="mr-3" variant="danger">Cancel</b-button>
            <b-button class="ml-2" variant="success" :disabled="unlock_reason && unlock_reason.length < 10" size="sm"
              @click="submitattendanceunlock()">Proceed</b-button>
          </b-col>
        </div>
      </b-modal>

      <!-- <b-form inline style="border: none;" class="p-2" v-if="course_id">
        <b-form-group>
          <label class="" style="justify-content: left;" for="inline-form-input-from">From (optional):</label>
          <b-form-datepicker aria-placeholder="From" id="inline-form-input-from" v-model="date_from" class="mr-2">
          </b-form-datepicker>
        </b-form-group>
        <b-form-group>
          <label class="" style="justify-content: left;" for="inline-form-input-to">To (optional):</label>
          <b-form-datepicker aria-placeholder="To" id="inline-form-input-to" v-model="date_upto" class="mr-2">
          </b-form-datepicker></b-form-group>
        <b-button class="my-2" variant="primary" @click="getSubgroupAttendanceWithReport()" size="sm">Submit To View
          Attendance</b-button>
      </b-form> -->

      <div>
        <b-form-group v-if="!subgroupIdProp">
          <b-form-select v-model="subgroup_id" :options="subgroupOptions"
            @change="getSubgroupAttendanceWithReport()"></b-form-select>
        </b-form-group>
      </div>
      <b-form inline style="border: none;" class="p-2">
        <label class="sr-only" for="inline-form-input-from">From:</label>
        <b-form-datepicker @input="updateAttendanceDateInput" aria-placeholder="From" id="inline-form-input-from"
          v-model="date_from" class="mr-2">
        </b-form-datepicker>

        <label class="sr-only" for="inline-form-input-to">To:</label>
        <b-form-datepicker @input="updateAttendanceDateInput" aria-placeholder="To" id="inline-form-input-to"
          v-model="date_upto" class="mr-2">
        </b-form-datepicker>
        <b-button v-if="enableEditAttendanceButton" variant="primary" @click="getSubgroupAttendanceWithReport()"
          size="sm">Proceed To Edit</b-button>
        <span v-else class="text-danger" style="font-size: 13px !important; font-weight: bold;">
          Please choose any 30 day date range and proceed to edit attendance</span>
      </b-form>

      <b-row class="mb-1 mx-2" v-if="(viewReport === true)">
        <b-button v-if="(enableEdit && editedDateSessionsCoursesStudentsAttTypeCache.length)"
          @click="saveAttendanceChanges" :variant="'success'">Save
          Changes({{ editedDateSessionsCoursesStudentsAttTypeCache.length }})</b-button>

        <!-- <div v-if="!enableEdit">
          <b-button v-if="(numberOfDaysInDateRange > 0 && numberOfDaysInDateRange < 31)"
            @click="(enableEdit = !enableEdit)" class="ml-2" :variant="'success'">Proceed To Edit
            Attendance </b-button>
          <span class="text-danger" v-else style="font-size: 14px !important; font-weight: bold;">
            Please choose any 30 day date range and proceed to edit attendance</span>
        </div> -->

        <b-button @click="cancelEditAttendance()" class="ml-2" :variant="'danger'">Cancel Attendance
          Edit</b-button>
        <!-- <div class="ml-5 text-right"><b-button @click="getSubgroupAttendanceReportPdf" class=""
            :variant="'danger'">Download PDF</b-button></div> -->
      </b-row>
      <div class="table-container">
        <table v-if="(viewReport === true)" class="table table-bordered black-border mb-5">
          <thead style="position: sticky;top: 0;">
            <tr>
              <th style="min-width: 70px;" class="text-center text-white bg-dark"></th>
              <th style="min-width: 170px;" class="text-center text-white bg-dark"></th>
              <th :colspan="dateData.sessionCourseCount" v-for="(dateData, dateIndex) in datesHeaderRow"
                :key="dateIndex" class="text-center text-white bg-dark">
                {{ dateData.date }}
              </th>
            </tr>
            <tr>
              <th style="min-width: 70px;" class="text-center text-white bg-dark"></th>
              <th style="min-width: 170px;" class="text-center text-white bg-dark"></th>
              <th :colspan="sessionData.courseCount" v-for="(sessionData, sessionIndex) in dateSessionsHeaderRow"
                :key="sessionIndex" class="text-center text-white bg-dark">
                {{ sessionData.session }}
              </th>
            </tr>
            <tr>
              <th style="min-width: 70px;" class="text-center text-white bg-dark"></th>
              <th style="min-width: 170px;" class="text-center text-white bg-dark"></th>
              <th v-for="(data, sessionIndex) in dateSessionsCoursesHeaderRow" :key="sessionIndex"
                class="text-center text-white bg-dark">
                <span v-if="data.lock && !data.pending" @click="selectedSlot(data)" class="text-primary btn p-0 m-0"><i
                    class="fas fa-lock"></i></span>
                <span v-if="data.lock && data.pending" class="text-primary btn p-0 m-0"><i
                    class="fas fa-lock"></i><br>Unlock Request Pending</span>
              </th>
            </tr>
            <tr>
              <th style="min-width: 70px;" class="text-center text-white bg-dark">Roll<br>No</th>
              <th style="min-width: 170px;" class="text-center text-white bg-dark">Name</th>
              <th v-for="(data, sessionIndex) in dateSessionsCoursesHeaderRow" :key="sessionIndex"
                class="text-center text-white bg-dark">{{ data.courseName }} <br>
                {{ AttDateMarkedBy[data.attendance_date_id].join(', ') }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(student, dataIndex) in students" :key="dataIndex">
              <td class="bg-dark  text-white">{{ student.roll_number }}</td>
              <td style="position:sticky; left:5px;" class="bg-dark  text-white">{{ student.first_name }} {{
                student.last_name
                }}</td>
              <td v-for="(data, sessionIndex) in dateSessionsCoursesHeaderRow" :key="sessionIndex"
                v-b-popover.hover.top="studAttTypeOnDateSessionCourse(data.date, data.session_id, data.course_id, student.id) == null ? 'Attendance for this student has not been marked, this window can only be used for marking attendance once it has already been marked' : ''">
                <b-link class="px-2" :id="(data.date + '-' + data.session_id + '-' + data.course_id + '-' + student.id)"
                  v-if="(studAttTypeOnDateSessionCourse(data.date, data.session_id, data.course_id, student.id) && enableEdit)">
                  <span class="text-secondary"
                    @click="dateSessionsStudAttTypeSelect[data.date][data.session_id][student.id] = true;refreshAttTypeCache()"
                    v-if="editedStudAttTypeOnDateSessionCourse(data.date, data.session_id, data.course_id, student.id)">
                    {{ editedStudAttTypeOnDateSessionCourse(data.date, data.session_id, data.course_id, student.id)
                    }}-></span>
                  <span
                    @click="
                    dateSessionsStudAttTypeSelect[data.date][data.session_id][student.id] = true;refreshAttTypeCache()">
                    {{ studAttTypeOnDateSessionCourse(data.date, data.session_id, data.course_id, student.id) }}
                  </span>
                  <div v-if="!data.lock && dateSessionsStudAttTypeSelect[data.date][data.session_id][student.id]"
                    :target="(data.date + '-' + data.session_id + '-' + data.course_id + '-' + student.id)"
                    triggers="hover" placement="top">
                    <b-row class="mx-1">
                      <div v-for="attType in attendanceTypes" :key="attType.id">
                        <div @click="dateSessionsStudAttTypeSelect[data.date][data.session_id][student.id] = false;
                        editAttendance(data.date, data.session_id, data.course_id, student.id, attType.id)" :class="{
                          'att-type-box': true, 'selected-att-type-box': (studAttTypeOnDateSessionCourse(data.date, data.session_id, data.course_id, student.id) ==
                            attType.code), 'unselected-att-type-box': studAttTypeOnDateSessionCourse(data.date, data.session_id, data.course_id, student.id) !=
                              attType.code
                        }"> {{ attType.code }}</div>
                      </div>
                    </b-row>
                  </div>
                </b-link>
                <!-- no edit  -->
                <span
                  v-else-if="studAttTypeOnDateSessionCourse(data.date, data.session_id, data.course_id, student.id)">{{
                  studAttTypeOnDateSessionCourse(data.date,
                  data.session_id, data.course_id, student.id)
                  }}</span>
                <span v-else>

                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="(viewReport === null)">Loading Schedules...</div>
      <div v-if="(viewReport === false)">Data not Available</div>
    </b-col>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["subgroupIdProp"],
  async created() {
    if (!this.subgroupIdProp) {
      await this.getMySubgroups();
    }
    else {
      this.subgroup_id = this.subgroupIdProp
      // this.getSubgroupAttendanceWithReport();
    }

    // --------- for debug-----------------
    // this.subgroup_id = 28;
    // this.getSubgroupAttendanceWithReport();
    // this.enableEdit = true;
    // --------- for debug-----------------
  },
  components: {
  },
  data() {
    return {
      date_from: '',
      date_upto: '',
      viewingReportDateRange: {
        date_upto: null,
        date_from: null,
      },
      enableEditAttendanceButton:false,
      selectedSlots:{
        date: '',
        sessions: [],
        course_id: '',
        lock: false
      },
      unlock_reason:'',
      viewReport: false,
      enableEdit: false,
      weekdayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      subgroupOptions: [],
      subgroup_id: null,

      sessions: {},
      attendanceTypes: {},
      students: {},

      AttDateMarkedBy: {},
      dateSessionsCoursesStudentsAttType: {},
      editedDateSessionsCoursesStudentsAttTypeCache: [], //original att type of editted attendances will be added to this
      dateSessionsCoursesAttDateId: {},
      dateSessionsCoursesAttLocks:{},
      datesHeaderRow: [],//[{date sessionCourseCount},..] 
      dateSessionsHeaderRow: [],//[{session,courseCount},..]
      dateSessionsCoursesHeaderRow: [], //[{date, session_id,sessionName,course_id, CourseName}]
      dateSessionsStudAttTypeSelect: [],
    };
  },
  computed: {
    numberOfDaysInDateRange() {
      this.date_from;
      this.date_upto;
      if (this.viewingReportDateRange.date_from && this.viewingReportDateRange.date_upto) {
        var start = moment(this.viewingReportDateRange.date_from);
        var end = moment(this.viewingReportDateRange.date_upto);
        if (start.format('DD/MM/YYYY') == end.format('DD/MM/YYYY')) return 1;
        return end.diff(start, "days");
      }
      return 0;
    },
  },
  methods: {
    selectedSlot(data) {
      this.selectedSlots.date = data.date;
      this.selectedSlots.sessions = data.session_id;
      this.selectedSlots.course_id = data.course_id;
      this.selectedSlots.lock = data.lock;
      this.$bvModal.show('unlockAttendanceModel')
    },
    async submitattendanceunlock() {
      this.selectedSlots.date = moment(String(this.selectedSlots.date)).format('YYYY-DD-MM');
      const url = this.$store.getters.getAPIKey.mainAPI + `/attendanceunlocks`;
      await this.$axios
        .post(url, {
          date: this.selectedSlots.date,
          sessions: [this.selectedSlots.sessions],
          course: this.selectedSlots.course_id,
          subgroup_id: this.subgroup_id,
          reason: this.unlock_reason,
          lock: this.selectedSlots.lock
        })
        .then((response) => {
          response;
          this.$bvModal.hide('unlockAttendanceModel')
        })
        .catch((error) => {
          error;
        });
    },
    cancelEditAttendance(){
      const result = window.confirm('The changes that you have made has will not been saved.');
      if (result)  this.$emit('closeEditAttendanceWindow');
    },
    async updateAttendanceDateInput() {
      let newEndDate = null;
      if (this.date_from != '' && this.date_upto != '') {
        const diffInTime = new Date(this.date_upto) - new Date(this.date_from);
        const diffInDays = diffInTime / (1000 * 3600 * 24);
        if (diffInDays > 30) {
          console.log('diffInDays', diffInDays);
          newEndDate = new Date(this.date_from);
          newEndDate.setDate(newEndDate.getDate() + 29);
        }
        this.enableEditAttendanceButton = true;
      } else {
        this.enableEditAttendanceButton = false;
      }
      if (newEndDate) {
        this.$toast.error("You can only edit attendance of any 30 days at a time", {
          position: "top",
          duration: 5000,
        });
        setTimeout(() => {
          this.date_upto = newEndDate;
        }, 200);
      }
    }, 
    async refreshAttTypeCache() {
      let editedDateSessionsCoursesStudentsAttTypeCache = this.editedDateSessionsCoursesStudentsAttTypeCache;
      this.editedDateSessionsCoursesStudentsAttTypeCache = [];
      this.editedDateSessionsCoursesStudentsAttTypeCache = editedDateSessionsCoursesStudentsAttTypeCache;
    },
    async saveAttendanceChanges() {
      let attTypeId = null;
      let attDateId = null;
      let newAttendances = [];
      this.editedDateSessionsCoursesStudentsAttTypeCache.forEach(cache => {
        attTypeId = this.dateSessionsCoursesStudentsAttType[cache.date][cache.session_id][cache.course_id][cache.student_id];
        attDateId = this.dateSessionsCoursesAttDateId[cache.date][cache.session_id][cache.course_id];
        newAttendances.push({
          attendance_date_id: attDateId,
          attendee_id: cache.student_id,
          attendance_type_id: attTypeId,
        });
      });


      const url = `${this.$store.getters.getAPIKey.mainAPI}/attendancedates/usersubgroup/editandreport/save`;
      await this.$axios
        .post(url, {
          usersubgroup: this.subgroup_id,
          newAttendances: newAttendances,
        })
        .then(() => {
          this.$toast.success("Attendance data updated successfully", {
            position: "top",
            duration: 4000,
          });
        })
        .catch(() => {
          this.$toast.error("Failed to update, please try again", {
            position: "top",
            duration: 4000,
          });
        });

      // post data on success 
      // clear cache 
      this.editedDateSessionsCoursesStudentsAttTypeCache = [];
    },
    async editAttendance(date, sessionId, courseId, studentId, newAttTypeId) {
      // if any attendance changes then the original attendance will be backuped to cache 
      // for future compare and process when save
      let cacheIndex = null;
      await this.editedDateSessionsCoursesStudentsAttTypeCache.forEach((cache, index) => {
        if (cache.date == date && cache.session_id == sessionId && cache.course_id == courseId && cache.student_id == studentId) {
          cacheIndex = index;
        }
      });
      if (cacheIndex === null) {
        // a new change
        let originalAttTypeId = this.dateSessionsCoursesStudentsAttType[date][sessionId][courseId][studentId];
        if (originalAttTypeId == newAttTypeId) return;
        this.editedDateSessionsCoursesStudentsAttTypeCache.push(
          { date: date, session_id: sessionId, course_id: courseId, student_id: studentId, attendance_type_id: originalAttTypeId }
        );
        this.dateSessionsCoursesStudentsAttType[date][sessionId][courseId][studentId] = newAttTypeId;
      } else {
        // already changed
        let originalAttTypeId = this.editedDateSessionsCoursesStudentsAttTypeCache[cacheIndex].attendance_type_id;
        if (newAttTypeId == originalAttTypeId) { // return to original
          this.dateSessionsCoursesStudentsAttType[date][sessionId][courseId][studentId] = newAttTypeId;
          this.editedDateSessionsCoursesStudentsAttTypeCache.splice(cacheIndex, 1);
        } else { // change
          this.dateSessionsCoursesStudentsAttType[date][sessionId][courseId][studentId] = newAttTypeId;
        }
      }
      // console.log();
      // this.$forceUpdate();
    },
    editedStudAttTypeOnDateSessionCourse(date, sessionId, courseId, studentId) {
      // date; sessionId; studentId;
      // if editted then return original to show refrence
      let attendance = null;
      this.editedDateSessionsCoursesStudentsAttTypeCache.forEach(cache => {
        if (cache.date == date && cache.session_id == sessionId && cache.course_id == courseId && cache.student_id == studentId) {
          attendance = this.attendanceTypes[cache.attendance_type_id].code;
        }
      });
      return attendance;
    },
    studAttTypeOnDateSessionCourse(date, sessionId, courseId, studentId) {
      if (date in this.dateSessionsCoursesStudentsAttType) {
        if (sessionId in this.dateSessionsCoursesStudentsAttType[date]) {
          if (courseId in this.dateSessionsCoursesStudentsAttType[date][sessionId]) {
            if (studentId in this.dateSessionsCoursesStudentsAttType[date][sessionId][courseId]) {
              let attType = this.dateSessionsCoursesStudentsAttType[date][sessionId][courseId][studentId];
              if (attType != null) {
                return this.attendanceTypes[attType].code;
              }
            }
          }
        }
      }
      return null;
    },
    async getSubgroupAttendanceWithReport() {
      this.enableEdit = false;

      this.viewReport = null;
      this.sessions = {};
      this.attendanceTypes = {};
      this.students = {};
      this.AttDateMarkedBy = {};
      this.dateSessionsCoursesStudentsAttType = {};
      this.editedDateSessionsCoursesStudentsAttTypeCache = [];
      this.dateSessionsCoursesAttDateId = {};
      this.dateSessionsCoursesHeaderRow = [];
      this.dateSessionsHeaderRow = [];
      this.datesHeaderRow = [];
      if (!this.subgroup_id) return;

      const url = `${this.$store.getters.getAPIKey.mainAPI}/attendancedates/usersubgroup/editandreport`;
      var responseData = [];
      await this.$axios
        .post(url, { usersubgroup: this.subgroup_id, start_date: this.date_from, upto_date: this.date_upto })
        .then((response) => {
          responseData = response.data;
        })
        .catch((error) => {
          error;
          this.viewReport = false;
          return;
        });

      this.sessions = responseData.sessions;
      this.attendanceTypes = responseData.attendanceTypes;
      this.students = responseData.students;
      this.courses = responseData.courses;

      this.AttDateMarkedBy = responseData.AttDateMarkedBy;
      this.dateSessionsCoursesStudentsAttType = responseData.dateSessionsCoursesStudentsAttType;
      this.dateSessionsCoursesAttDateId = responseData.dateSessionsCoursesAttDateId;
      this.dateSessionsCoursesAttLocks = responseData.dateSessionsCoursesAttLocks;
      // backup for edit reference
      // this.dateSessionsStudentsAttTypeCache = responseData.dateSessionsStudentsAttType;

      this.datesHeaderRow = [];//{date,sessionCourseCount}
      this.dateSessionsHeaderRow = []; //{session,courseCount},...
      this.dateSessionsCoursesHeaderRow = [];
      this.dateSessionsStudAttTypeSelect = [];
      for (const date in this.dateSessionsCoursesAttDateId) {
        let sessionCourseCount = 0;
        for (const sessionId in this.dateSessionsCoursesAttDateId[date]) {
          this.dateSessionsStudAttTypeSelect[date] = [];
          let courseCount = 0;
          for (const courseId in this.dateSessionsCoursesAttDateId[date][sessionId]) {
            this.dateSessionsStudAttTypeSelect[date][sessionId] = [];
            courseCount++;
            sessionCourseCount++;
            this.dateSessionsCoursesHeaderRow.push({
              date: date,
              session_id: sessionId,
              course_id: courseId,
              attendance_date_id: this.dateSessionsCoursesAttDateId[date][sessionId][courseId],
              sessionName: this.sessions[sessionId].name,
              courseName: this.courses[courseId].code,
              lock: this.dateSessionsCoursesAttLocks[date][sessionId][courseId]['lock'],
              pending: this.dateSessionsCoursesAttLocks[date][sessionId][courseId]['pending_request'],
              atttypeSelect: false,
            });
          }
          this.students.forEach(Student => {
            this.dateSessionsStudAttTypeSelect[date][sessionId][Student.id] = false;
          }); 
          this.dateSessionsHeaderRow.push({ session: this.sessions[sessionId].name, courseCount: courseCount });
        }
        this.datesHeaderRow.push({ date: date, sessionCourseCount: sessionCourseCount });
      }

      if (this.date_from && this.date_upto) {
        this.viewingReportDateRange.date_from = this.date_from;
        this.viewingReportDateRange.date_upto = this.date_upto;
      }
      this.viewReport = true;
      this.enableEdit=true;
    },

    async getMySubgroups() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/institutionuser/usersubgroups`;
      await this.$axios
        .get(url)
        .then((response) => {
          let responseData = response.data;

          this.subgroupOptions = [
            { value: null, text: "Select" },
          ];

          responseData.forEach((subgroup) => {
            this.subgroupOptions.push({
              value: subgroup.id,
              text: `${subgroup.name}(${subgroup.code})`,
            });
          });
        })
        .catch(() => {
          // console.log(error.response);
        });
    },

    async getSubgroupAttendanceReportPdf() {
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/attendancereports/usersubgroup/detailedreport/pdfexport`;
      await this.$axios
        .post(
          url,
          { usersubgroup: this.subgroup_id, start_date: this.date_from, upto_date: this.date_upto },
          { responseType: "blob" }
        )
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "studentVsDateClassAttendance.pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch(() => {
          let errorText = "Failed to Download, please try again";
          this.$toast.error(errorText, {
            position: "top",
            duration: 3000,
          });
        });
    },
  },
};
</script>

<style >
.tag-label {
  max-width: 80px;
  /* Adjust the width as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.c-border {
  border: 2px solid;
}

.att-type-box {
  height: 20px;
  min-width: 20px;
  text-align: center;
  cursor: pointer;
  border-radius: 6px;
  padding: 2px;
  margin: 2px;
}

.selected-att-type-box {
  background-color: #10dc10;
}

.unselected-att-type-box {
  background-color: #d3d3d3;
}

.table-container {
  max-height: 90vh !important;
  /* Adjust the maximum height as needed */
  overflow-y: auto !important;
}

.black-border th,
.black-border td,
.black-border tr {
  border: 1px solid rgb(175, 175, 175);
}
</style>
