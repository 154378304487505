<template>
  <div>
    <b-col sm="12">
      <h3 style="text-align: center; font-size:18px !important;">
        Mark Attendance
      </h3>

      <b-form-group id="input-group-1" label-for="input-1" description="">
        <label class="required-label" for="">Course:</label>
        <b-form-select v-model="selectedSlots.course_id" :options="courses" size="sm"></b-form-select>
      </b-form-group>
      <b-form-group id="input-group-1" label-for="input-1" description="">
        <label class="required-label" for="">Date:</label><b-form-datepicker id="example-datepicker"
          v-model="selectedSlots.date"></b-form-datepicker>
      </b-form-group>
      <b-form-group>
        <label class="required-label" for="">Sessions:</label>
        <b-form-checkbox-group @change="checkAttendanceSlots()" id="checkbox-group-1" v-model="selectedSlots.sessions"
          :options="sessions" name="flavour-1"></b-form-checkbox-group>
      </b-form-group>

      <b-button @click="$bvModal.hide('customDateAttendanceMarkModel')" class="mr-3" variant="danger">Cancel</b-button>
      <b-button v-if="!selectedSlots.lock" variant="primary" @click="markNewAttendance">Proceed</b-button>
      <span v-if="selectedSlots.lock">
        <span class="text-info" v-if="!selectedSlots.pending">
          <span v-if="selectedSlots.approved_unlock_sessions.length>0">
            Session {{ selectedSlots.approved_unlock_sessions }} has
            been locked.
            Send Unlock Request?
          </span>
          <span class="text-info" v-else>
            Attendance locked.
            Send Unlock Request?
          </span>
        </span>
        <span class="text-info" v-else>
          Attendance locked.Unlock request for <span></span>session {{ selectedSlots.pending_sessions }} is
          pending</span>
        <b-button class="ml-2" variant="success" size="sm" v-if="!selectedSlots.pending"
          @click="$bvModal.show('unlockAttendanceModel')">Unlock</b-button>
      </span>
    </b-col>
    <b-modal id="unlockAttendanceModel" no-close-on-backdrop no-close-on-keyboard no-close-on-esc hide-header-close
      hide-footer hide-header centered size="md">
      <div>
        <b-col sm="12">
          <h3 style="text-align: center; font-size:18px !important;">
            Unlock Attendance
          </h3>
          <b-form-group>
            <label class="required-label" for="">Reason (Minimum 10 character):</label>
            <b-form-input id="input-1" v-model="unlock_reason" type="text" placeholder="Enter Reason"></b-form-input>
          </b-form-group>
          <b-button @click="$bvModal.hide('unlockAttendanceModel')" class="mr-3" variant="danger">Cancel</b-button>
          <b-button class="ml-2" variant="success" :disabled="unlock_reason && unlock_reason.length < 10" size="sm"
            @click="submitattendanceunlock()">Proceed</b-button>
        </b-col>
      </div>
    </b-modal>
  </div>

</template>

<script>
// import moment from "moment";
export default {
  props: ["sessionsProp"],
  async created() {
    if (this.sessionsProp == null || this.sessionsProp == undefined) {
      await this.getSessions();
    } else {
      this.sessionsProp.forEach((session) => {
        this.sessions.push({
          value: session.id,
          text: `${session.name}`,
        });
      });
    }

    this.getMyCourses();
  },
  components: {
  },
  data() {
    return {
      courses: [],
      sessions: [],
      unlock_reason:'',
      selectedSlots: {
        date: new Date(),
        sessions: [],
        course_id: '',
        subgroup_id:'',
        confirm_text: '', // pass to att mark form
        lock:false,
        pending:false,
        pending_sessions:null,
        approved_unlock_sessions:null
      },
    };
  },
  watch:{
'selectedSlots.course_id':function(){
      this.courses.forEach(course => {
        if (course.value == this.selectedSlots.course_id)
          this.selectedSlots.subgroup_id = course.subgroup_id;
      });

    }
  },
  methods: {
    async submitattendanceunlock() {
      const url = this.$store.getters.getAPIKey.mainAPI + `/attendanceunlocks`;
      await this.$axios
        .post(url, {
          date: this.selectedSlots.date,
          sessions: this.selectedSlots.sessions,
          course: this.selectedSlots.course_id,
          subgroup_id: this.selectedSlots.subgroup_id,
          reason: this.unlock_reason,
          lock: this.selectedSlots.lock
        })
        .then((response) => {
          response;
          this.$bvModal.hide('unlockAttendanceModel');
          this.unlock_reason = null;
          this.checkAttendanceSlots()
        })
        .catch((error) => {
          error;
        });
    },
    async checkAttendanceSlots() {
      const url = `${this.$store.getters.getAPIKey.mainAPI}/attendances/checkslot`;
      await this.$axios
        .post(url, {
          date: this.selectedSlots.date,
          sessions: this.selectedSlots.sessions,
          course: this.selectedSlots.course_id
        })
        .then((response) => {
          let respData = response.data;
          this.selectedSlots.lock = respData.lock;
          this.selectedSlots.approved_unlock_sessions = respData.unlockApprovedSessions;
          if (respData.unlockPendingSessions) {
            this.selectedSlots.pending = true;
            this.selectedSlots.pending_sessions = respData.unlockPendingSessions;
          }
          else {
            this.selectedSlots.pending = false;
          }
        })
        .catch((error) => {
          error;
        });
    },

    markNewAttendance() {

      let tempDate = new Date(this.selectedSlots.date).toLocaleDateString();
      let sessionNames = '';
      this.sessions.forEach(session => {
        if (this.selectedSlots.sessions.includes(session.value)) sessionNames += session.text + ', ';
      });
      let tempCourse = '';
      this.courses.forEach(course => {
        if (course.value == this.selectedSlots.course_id) tempCourse = course.text;
      });
      this.selectedSlots.confirm_text = 'Mark attendance for ' +
        tempCourse + ' on ' + tempDate + ' and sessions: ' + sessionNames;

      this.$emit('markNewAttendance', this.selectedSlots)
    },

    async getSessions() {
      const url = this.$store.getters.getAPIKey.createSessions;
      await this.$axios
        .get(url)
        .then((response) => {
          let sessions = response.data;
          sessions.forEach((session) => {
            this.sessions.push({
              value: session.id,
              text: `${session.name}`,
            });
          });
        })
        .catch((error) => {
          error;
        });
    },

    async getMyCourses() {
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/institutionuser/courses`;
      await this.$axios
        .get(url)
        .then((response) => {
          let responseData = response.data;
          this.courses = [
            { value: null, text: "Select Course" },
          ];

          responseData.forEach((course) => {
            this.courses.push({
              value: course.id,
              text: `${course.name}(${course.usersubgroup.code}-${course.usersubgroup.usergroup.code})`,
              subgroup_id: course.usersubgroup.id,
            });
          });
        })
        .catch(() => {
          // console.log(error.response);
        });
    },
  },
};
</script>

<style  >

</style>
